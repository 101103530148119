<template>
  <div>
    <h6 class="font-weight-bolder">Address & Contact Details</h6>
    <div class="row px-2 mb-4">
      <div class="col-6">
        <div class="card p-2">
          <div class="card-title mb-0 pb-0">
            <p class="font-weight-bolder pb-1 mb-0">Addresses</p>
            <hr class="mt-0 text-dark" >
          </div>
          <div class="card-body p-0">
            <template v-if="crewInfo.crewAddresses">
              <div v-for="address in crewInfo.crewAddresses">
                <span class="py-2 d-block font-weight-bold">{{address.addressType ? address.addressType.toUpperCase() : ''}} ADDRESS</span>
                <table class="w-100">
                  <tr>
                    <td style="width: 25%">Region:</td>
                    <td>{{address.region ? address.region.toUpperCase() : ''}}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">City:</td>
                    <td>{{address.city ? address.city.toUpperCase() : ''}}</td>
                  </tr>
                  <tr>
                    <td style="width: 25%">Address:</td>
                    <td>{{address.address ? address.address.toUpperCase() : ''}}</td>
                  </tr>
                </table>

              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card p-2">
          <div class="card-title mb-0 pb-0">
            <p class="font-weight-bolder pb-1 mb-0">Contact Details</p>
            <hr class="mt-0 text-dark">
          </div>
          <div class="card-body p-0">
            <table class="w-100 mb-1">
              <tr>
                <td style="width: 25%;">Email Address</td>
                <td>{{crewInfo.emailAddress}}</td>
              </tr>
            </table>
            <template v-if="crewInfo.crewContacts">
              <table class="w-100 mb-1" v-for="contact in crewInfo.crewContacts">
                <tr>
                  <td style="width: 25%;">{{ contact.contactType ? contact.contactType.toUpperCase() : '' }}</td>
                  <td>{{contact.contact ? contact.contact : ''}}</td>
                </tr>
              </table>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CrewAddress",
  computed:{
    ...mapGetters(['crewInfo'])
  },
}
</script>

<style scoped>

</style>
